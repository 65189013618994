import React, {useEffect, Fragment} from 'react'
import {connect} from 'react-redux'

// Custom components
import TruncateText from '../../common/TruncateText'
import Spinner from '../../common/Spinner'
import Currency from '../../common/Currency'
import CintQuotaOverview from './cint/CintQuotaOverview'
import CintFiltersOverview from './cint/CintFiltersOverview'

import {
  getActivityLanguageById,
  getFormattedPrescreenID,
  getPreviousParticipationText,
  isGuidedTrackLink,
  parseFilterValue
} from './RunUtils'
import {TABS} from './EnhancedRunForm'
import {errorToast} from '../../helpers/notification'

const RunFormOverview = (props) => {
  const isPrescreenSelected = () => {
    if (['project_include', 'project_exclude'].includes(props.prescreenData.ps_type))
      return true

    return props.prescreenData.ps_type === 'custom' && !(
      (props.prescreenData.ps_level === 'runs' || props.prescreenData.ps_level === 'projects')
      && props.prescreenIds.length === 0
    )
  }

  const showEligibleParticipants = () => {
    return props.isQualifiersCountLoading ||
      props.isCintQualifiersCountLoading ||
      (props.isMturk && props.eligibleParticipantsCount !== null && !isNaN(props.eligibleParticipantsCount)) ||
      (props.isCint && props.eligibleParticipantsCountForCint !== null && !isNaN(props.eligibleParticipantsCountForCint))
  }

  const getActivityLanguage = () => (
    getActivityLanguageById(
      props.languages,
      props.activityLanguageId
    ).label
  )

  const setActiveTab = (e, tabName) => {
    e.preventDefault()
    props.setActiveTab(tabName)
  }

  useEffect(() => {
    if (!props.eligibleParticipantsCountErrors?.length) return

    errorToast(props.eligibleParticipantsCountErrors.join(', '))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, props.eligibleParticipantsCountErrors)

  // UI template
  return (
    <>
      {!props.isTabActive(TABS.DETAILS) && (
        <div className="box completion-pane-bg pos-sticky">
          <h2 className="big">Completions</h2>
          <a
            href="/"
            className="link_icon clickable"
            onClick={e => setActiveTab(e, TABS.DETAILS)}
          >
            {''}
          </a>
          <div className="wrap_form">
            <div className="wrap_left">
              <div className="wrap_icon box_1">
                <div className="icon icon-customize icon-activity"/>
                <p>Completions</p>
                <span>{props.task_parameters.number_of_submissions}</span>
              </div>
            </div>
            <div className="wrap_right">
              <div className="wrap_icon box_2">
                <div className="icon icon-customize icon-bax"/>
                <p className="pl-1">Pay</p>
                <span className="pl-1">
                  <Currency value={props.task_parameters.payment_per_submission}/>
                </span>
              </div>
            </div>
          </div>
          <div className="wrap_form">
            <div className="wrap_left">
              <div className="wrap_icon box_3">
                <div className="icon icon-customize icon-clock-circle"/>
                <p>Time</p>
                <span>{props.task_parameters.max_time_per_submission}</span>&nbsp;
                {(props.task_parameters.max_time_per_submission &&
                  <span>{props.task_parameters.max_time_type}</span>
                )}
              </div>
            </div>
            <div className="wrap_right no_active">
              <div className="wrap_icon box_4">
                <div className="icon icon-customize icon-money"/>
                <p className="pl-1">{props.agree_screening_guidelines ? 'Max budget' : 'Cost'}</p>
                <span className="pl-1">
                  <Currency
                    value={props.agree_screening_guidelines
                      ? props.task_parameters.est_max_budget || ''
                      : props.formattedTotalRunCost
                    }
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="table_wrap mt-3">
            <span className="heading-inline">Country</span>
            <span>{props.task_parameters.country_name}</span>
          </div>
          <div className="table_wrap mt-2">
            <span className="heading-inline flex-no-shrink">Platform</span>
            <span className="lh-1">{props.platform.full_name}</span>
          </div>
          <div className="table_wrap mt-2">
            <span className="heading-inline">Language</span>
            <TruncateText text={getActivityLanguage()} max={23}/>
          </div>

          <h2 className="big next">Participants</h2>
          <a
            href="/"
            className="link_icon clickable"
            onClick={e => setActiveTab(e, TABS.PARTICIPANTS)}
          >
            {''}
          </a>

          {showEligibleParticipants() && (
            <div className="line_wrap mt-2">
              <div className="line customize-line">
                <div className="icon participants-icon"/>
                <div className="wrap_text pl-2 pr-0 pt-0">
                  {props.isMturk && (props.isQualifiersCountLoading
                      ? <Spinner className="ml-3" style={{marginTop: '-4px'}}/>
                      : (
                        <>
                          <div className="desc">Eligible participants</div>
                          <div className="title font-size-16">
                            {new Intl.NumberFormat('en-US').format(props.eligibleParticipantsCount)}
                          </div>
                        </>
                      )
                  )}

                  {props.isCint && (props.isCintQualifiersCountLoading
                      ? <Spinner className="ml-3" style={{marginTop: '-4px'}}/>
                      : (
                        <>
                          <div className="desc">Eligible participants</div>
                          <div className="title font-size-16">
                            {new Intl.NumberFormat('en-US').format(props.eligibleParticipantsCountForCint)}
                          </div>
                        </>
                      )
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="wrap_tables mb-2 mb-1">
            {props.isMturk && (
              <>
                {props.demographicFilters && props.demographicFilters.length > 0 && props.demographicFilters
                  .map((filter, index) => (
                    <Fragment key={index}>
                      {(
                        filter.is_filter_valid
                        && (filter.answer_type === 'select_box' || filter.answer_type === 'check_box')
                        && filter.filter_options.some(filterOption => filterOption.is_checked)
                      ) && (
                        <div className="mt-3" key={index}>
                          <div className="table_wrap">
                            <span className="heading">
                              {filter.filter_label}
                            </span>
                          </div>
                          {filter.filter_options.map((filterOption, index) =>
                            filterOption.is_checked ? (
                              <div key={index}>
                                <div className="table_wrap mb-1">
                                  <span/>
                                  <span className="success-tick filter-list">
                                    {filterOption.label}
                                  </span>
                                </div>
                              </div>
                            ) : null
                          )}
                        </div>
                      )}

                      {(
                        filter.is_filter_valid
                        && filter.answer_type === 'text' && filter.filter_type === 'numeric'
                      ) && (
                        <div key="demographic-age-filter">
                          <div className="table_wrap my-3">
                            <span className="run-form-overview-heading">
                              {filter.filter_label}
                            </span>
                            <span>
                              {filter.from} - {filter.to}
                            </span>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ))
                }

                {(
                  props.customAttributeFilters && props.customAttributeFilters.length > 0
                  && props.customAttributeFilters.some(filter => filter.is_filter_valid)
                ) && (
                  <div className="wrap_tables po-text mt-3 word-break-all">
                    <span className="run-form-overview-heading">Custom Attributes</span>
                    {props.customAttributeFilters.map((filter, index) => (
                      filter.is_filter_valid ? (
                        <p key={index} className="completions-po-text mb-2">
                          <span>
                            {filter.mode} people with&nbsp;
                            <b className="text-dark">{filter.name}</b>&nbsp;
                            {filter.comparator}&nbsp;
                            <b className="text-dark">{parseFilterValue(filter)}</b>
                          </span>
                        </p>
                      ) : null
                    ))}
                  </div>
                )}
              </>
            )}

            {props.prescreenData.ps_type && props.prescreenData.ps_type !== 'does_not_matter' && (
              <div className="mt-4">
                {isPrescreenSelected() && (
                  <div className="wrap_tables po-text">
                    <span className="run-form-overview-heading">
                      Previous participation
                    </span>
                    <div className="completions-po-text">
                      <p className="mb-2">
                        {getPreviousParticipationText(props.prescreenData)}
                      </p>
                      {(props.prescreenData.ps_level === 'runs' || props.prescreenData.ps_level === 'projects') && (
                        <div className="radio-toolbar">
                          {props.prescreenIds.map((id, index) => (
                              <label key={index} className="mb-2 pl-2 pr-2">
                                <span>{getFormattedPrescreenID(id)}</span>
                              </label>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            {props.isCint && !props.isCintFiltersLoading && (
              <>
                <CintFiltersOverview/>
                <CintQuotaOverview/>
              </>
            )}
          </div>

          {props.agree_screening_guidelines && (
            <div className="table_wrap mt-3">
              <span className="heading-inline">Screening link</span>
              <span>Enabled</span>
            </div>
          )}

          <h2 className="big next mb">Activity</h2>
          <a
            href="/"
            className="link_icon clickable"
            onClick={e => setActiveTab(e, TABS.ACTIVITY)}
          >
            {''}
          </a>
          <div className="table_wrap">
            <span className="heading-inline">Title</span>
            <TruncateText text={props.task_parameters.title} max={23}/>
          </div>
          {props.isMturk && (
            <div className="table_wrap">
              <span className="heading-inline">Description</span>
              <TruncateText text={props.task_parameters.description} max={23}/>
            </div>
          )}
          <div className="table_wrap hosted-task-url">
            <span className="heading-inline">
              {props.isMturk && props.agree_screening_guidelines ? 'Main activity link' : 'Link'}
            </span>
            <TruncateText text={props.task_parameters.task_url} max={23}/>
          </div>
          {props.isMturk && props.agree_screening_guidelines && (
            <div className="table_wrap hosted-task-url">
              <span className="heading-inline">Screening activity link</span>
              <TruncateText text={props.task_parameters.screening_activity_link} max={23}/>
            </div>
          )}
          {isGuidedTrackLink(props.task_parameters.task_url) && props.task_parameters.task_data_url && (
            <div className="table_wrap hosted-task-url">
              <span className="heading-inline">Data link</span>
              <TruncateText text={props.task_parameters.task_data_url} max={23}/>
            </div>
          )}
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => {
  if (!state.runFormOverview || !state.runFormOverview.filters)
    return {task_parameters: {}, prescreenData: {}}

  return {
    task_parameters: state.runFormOverview.task_parameters || {},
    formattedTotalRunCost: state.runFormOverview.formattedTotalRunCost || 0,
    demographicFilters: state.runFormOverview.filters.filter(filter => filter.is_demographic) || [],
    customAttributeFilters: state.runFormOverview.filters.filter(filter => !filter.is_demographic) || [],
    prescreenData: state.runFormOverview.prescreen_data || {},
    prescreenIds: state.runFormOverview.prescreen_ids || [],
    cintFilters: state.runFormOverview.cint_filters || [],
    isQualifiersCountLoading: state.runForm.qualifiersCount?.isLoading,
    eligibleParticipantsCount: state.runForm.qualifiersCount?.total,
    isCintQualifiersCountLoading: state.runForm.cintQualifiersCount?.isLoading,
    eligibleParticipantsCountForCint: state.runForm.cintQualifiersCount?.total,
    eligibleParticipantsCountErrors: state.runForm.cintQualifiersCount?.errors || state.runForm.qualifiersCount?.errors,
    languages: state.activityLanguages?.languages,
    activityLanguageId: state.runFormOverview.activity_language_id,
    agree_screening_guidelines: state.runFormOverview.agree_screening_guidelines,
    isCintFiltersLoading: state.filtersList?.cintProfiling?.isLoading,
  }
}


export default connect(mapStateToProps)(RunFormOverview)
